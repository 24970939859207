import React from "react";

const AppLoader = () => {
  const styles: React.CSSProperties = {
    width: "0px",
    height: "0px",
    visibility: "hidden",
    position: "fixed",
    left: 0,
    bottom: 0,
  };
  return (
    <>
      <div style={{ display: "none" }}>
        <img src="/img/brand-influence.png" alt="" />
        <img src="/img/governance.png" alt="" />
        <img src="/img/operations-supply-chain.png" alt="" />
        <img src="/img/purpose.png" alt="" />
        <img src="/img/operations-supply-chain.png" alt="" />
        <img src="/img/arrow.png" alt="" />
      </div>
      <div
        style={{
          fontWeight: 400,
          ...styles,
        }}
      >
        .
      </div>
      <div
        style={{
          fontWeight: 600,
          ...styles,
        }}
      >
        .
      </div>
      <div
        style={{
          fontWeight: 700,
          ...styles,
        }}
      >
        .
      </div>
    </>
  );
};

export default AppLoader;
