/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import Menu from "../../components/Menu/Menu";
import ViewLoader from "../../components/ViewLoader/ViewLoader";
import InfoBox from "../../components/InfoBox/InfoBox";
import NoFound from "../../components/NoFound/NoFound";
import { ROUTES } from "../../constants";
import DetailedResults from "../../components/DetailedResults/DetailedResults";

const DetailedResultsPage = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    ctx?.setTitle(`${ROUTES.detailedResults.title}`);
    setLoading(false);
  }, []);

  return (
    <>
      <Menu />
      {loading ? (
        <ViewLoader />
      ) : !!ctx?.apiCall?.detailedResults?.length ? (
        <>
          <DetailedResults apiData={ctx?.apiCall?.detailedResults} />
          <InfoBox />
        </>
      ) : (
        <NoFound />
      )}
    </>
  );
};

export default DetailedResultsPage;
