/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import Menu from "../../components/Menu/Menu";
import InfoBox from "../../components/InfoBox/InfoBox";
import NoFound from "../../components/NoFound/NoFound";
import { ROUTES } from "../../constants";
import AdditionalMenu from "../../components/Menu/AdditionalMenu";
import ImpactView from "../../components/ImpactView/ImpactView";

const RoleImpactPage = () => {
  const ctx = useContext(AppContext);

  useEffect(() => {
    ctx?.setTitle(`${ROUTES.roleImpact.title}`);
  }, []);

  return (
    <>
      <Menu back={`${ROUTES.groupComparison.path}`} />
      <AdditionalMenu
        additionalMenuCollection={[
          {
            path: `${ROUTES.positionImpact.path}`,
            title: `${ROUTES.positionImpact.title}`,
          },
          {
            path: `${ROUTES.ageImpact.path}`,
            title: `${ROUTES.ageImpact.title}`,
          },
        ]}
      />
      {!!ctx?.apiCall?.roleImpact ? (
        <>
          <ImpactView
            apiData={ctx?.apiCall?.roleImpact}
            impactViewClassName="ivRoleImpact"
          />
          <InfoBox />
        </>
      ) : (
        <NoFound />
      )}
    </>
  );
};

export default RoleImpactPage;
