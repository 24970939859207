/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";

const getApiUrl = () => {
  if (process.env.REACT_APP_VERSION === "pl")
    return "https://digital-emotions-2.azurewebsites.net/api/sustainable-brands-pl";
  if (process.env.REACT_APP_VERSION === "en")
    return "https://digital-emotions-2.azurewebsites.net/api/sustainable-brands";
};

class Api {
  service: AxiosInstance;

  constructor() {
    const service = axios.create({
      baseURL: getApiUrl(),

      responseType: "json",
    });

    service.interceptors.response.use(this.handleSuccess, this.handleError);

    this.service = service;
  }

  handleSuccess = (response: AxiosResponse) => {
    const successResponse = { ...response };
    const { data } = successResponse;
    return data || true;
  };

  handleError = (error: AxiosError) => {
    const errorResponse = { ...error };
    console.error(errorResponse);
    return null;
  };

  NoAuth = async ({ url = "", data = {}, headers = {}, method = "GET" }) => {
    return this.service
      .request({
        method,
        url,
        data,
        headers,
      })
      .then((response: any) => response);
  };
}

export default new Api();
