/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import Menu from "../../components/Menu/Menu";
import InfoBox from "../../components/InfoBox/InfoBox";
import GroupComparison from "../../components/GroupComparison/GroupComparison";

const GroupComparisonPage = () => {
  const ctx = useContext(AppContext);

  useEffect(() => {
    ctx?.setTitle(``);
  }, []);

  return (
    <>
      <Menu />
      <GroupComparison />
      <InfoBox />
    </>
  );
};

export default GroupComparisonPage;
