/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import Menu from "../../components/Menu/Menu";
import ViewLoader from "../../components/ViewLoader/ViewLoader";
import InfoBox from "../../components/InfoBox/InfoBox";
import NoFound from "../../components/NoFound/NoFound";
import { ROUTES } from "../../constants";
import DetailedResults from "../../components/DetailedResults/DetailedResults";
import { useParams } from "react-router-dom";

const DetailedResultsExplanationPage = () => {
  const ctx = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    ctx?.setTitle(
      `${ROUTES.detailedResults.title} | ${
        ctx?.apiCall?.detailedResultsExplanation?.find(
          (item: any) => item?.codename === params?.codename
        )?.details[0]?.title
      }`
    );
    setLoading(false);
  }, []);

  return (
    <>
      <Menu back={`${ROUTES.detailedResults.path}`} />
      {loading ? (
        <ViewLoader />
      ) : !!ctx?.apiCall?.detailedResultsExplanation?.length ? (
        <>
          <DetailedResults
            apiData={
              ctx?.apiCall?.detailedResultsExplanation?.find(
                (item: any) => item?.codename === params?.codename
              )?.details || null
            }
            explanation
          />
          <InfoBox />
        </>
      ) : (
        <NoFound />
      )}
    </>
  );
};

export default DetailedResultsExplanationPage;
