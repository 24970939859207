import React, { useContext } from "react";
import styles from "./Header.module.scss";
import { AppContext } from "../../App";

const Header = () => {
  const ctx = useContext(AppContext);
  return (
    <div className={styles.header}>
      <img src="/img/2.png" alt="" style={{ maxHeight: "58px" }} />
      <div className={styles.titleContainer}>
        <img src="/img/logo-text.png" alt="" style={{ maxHeight: "55px" }} />
        <div className={styles.title}>{ctx?.title}</div>
      </div>
      <img src="/img/3.png" alt="" style={{ maxHeight: "47px" }} />
    </div>
  );
};

export default Header;
