import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import styles from "./Menu.module.scss";
import { ROUTES } from "../../constants";
const Menu = ({ back = "" }) => {
  const location = useLocation();

  const pathname = location?.pathname?.split("/");
  return (
    <div className={styles.menu}>
      {!back ? (
        <>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `${styles.menuItem} ${styles.menuActiveItem}`
                : `${styles.menuItem}`
            }
            to={ROUTES.generalResults.path}
          >
            {ROUTES.generalResults.title}
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ||
              ROUTES.detailedResultsExplanation.path === `/${pathname[1]}/`
                ? `${styles.menuItem} ${styles.menuActiveItem}`
                : `${styles.menuItem}`
            }
            to={ROUTES.detailedResults.path}
          >
            {ROUTES.detailedResults.title}
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive
                ? `${styles.menuItem} ${styles.menuActiveItem}`
                : `${styles.menuItem}`
            }
            to={ROUTES.groupComparison.path}
          >
            {ROUTES.groupComparison.title}
          </NavLink>
        </>
      ) : (
        <Link
          className={`${styles.menuItem} ${styles.menuActiveItem} ${styles.menuItemBack}`}
          to={back}
        >
          <img src="/img/arrow.png" alt="" />
          back
        </Link>
      )}
    </div>
  );
};

export default Menu;
