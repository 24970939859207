import React from "react";
import { Link } from "react-router-dom";
import styles from "./Menu.module.scss";

const AdditionalMenu = ({
  additionalMenuCollection = [],
}: {
  additionalMenuCollection: any;
}) => {
  return (
    <div className={styles.aditionalMenu}>
      {additionalMenuCollection?.map(
        ({ path, title }: { path: string; title: string }, index: number) => (
          <Link key={index} className={styles.menuItem} to={path}>
            {title}
          </Link>
        )
      )}
    </div>
  );
};

export default AdditionalMenu;
