import React from "react";
import styles from "./GroupComparison.module.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants";

const GroupComparison = () => {
  return (
    <div className={`${styles.groupComparisonContainer}`}>
      <div className={styles.title}>Group comparisons</div>
      <Link className={styles.link} to={ROUTES.roleImpact.path}>
        Role Impact
      </Link>
      <Link className={styles.link} to={ROUTES.positionImpact.path}>
        Position Impact
      </Link>
      <Link className={styles.link} to={ROUTES.ageImpact.path}>
        Age Impact
      </Link>
    </div>
  );
};

export default GroupComparison;
